<app-head [bk]="nav.bk" [title]="title" [hidden]="addFo"></app-head>
<!-- {{airOrder|json}}
{{user|json}} -->
<air-brief [hidden]="addFo"></air-brief>
<div class="brief mt-3 mb-3" >
    <!-- <div *ngFor= "let add of airOrder.addList;let i =index">
        <add-brief [address]=add [index]=i></add-brief>
    </div> -->
    <add-brief [addList]="airOrder.addList" (changeIndex)="changeIndex($event)" [hidden]="addFo"></add-brief>
</div>
<add-input [dest]="false" [(form)]="addForm" (newAdd)="newAdd($event)" (addFoo)="addFoo($event)"></add-input>
<comm-input [hidden]="addForm.p_left.length != 0" [share]="airOrder.share" [outbound]="airOrder.flight.outbound" (submitOrder)="submitOrder($event)"></comm-input>
<!-- {{addForm|json}}
{{nav|json}} -->
<app-navbar [page]="2"></app-navbar>
<app-modal [modal]="modal"></app-modal>
<app-loading [hidden]="loadingHidden"></app-loading>
<!-- {{user|json}}  -->
