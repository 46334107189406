import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'comm-input',
  templateUrl: './comm-input.component.html',
  styleUrls: ['./comm-input.component.css']
})
export class CommInputComponent implements OnInit {
  @Input() share:boolean;
  @Input() outbound:boolean;
  @Input() ride:boolean = false;
  @Output() submitOrder=new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  check:boolean=false;
  usepoint:boolean=false;
  comm:string= "";

  submit(){
    // alert(this.comm);
    // alert(this.usepoint);
    this.submitOrder.emit([this.comm,this.usepoint]);
  }

}
