import { Component, OnInit,Input } from '@angular/core';
import { SharedService } from "../shared.service";
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  @Input() page:number;
  currentPage:number;
  constructor(private sharedService: SharedService,private router:Router) { }

  ngOnInit(): void {
    this.sharedService.currentPage.subscribe(page =>this.currentPage=page)
  }

  public icons=[
    {icon:'/assets/images/icon/home.png',name:'首页',click:'home'},
    {icon:'/assets/images/icon/tour.png',name:'旅游',click:'tour'},
    {icon:'/assets/images/icon/car.png',name:'约车',click:'book'},
    {icon:'/assets/images/icon/price.png',name:'价格',click:'price'},
    {icon:'/assets/images/icon/about.png',name:'关于',click:'about'},
    {icon:'/assets/images/icon/my.png',name:'我的',click:'my'},
  ];
  public btnClick(btn){
    if (btn!=this.page)     this.router.navigate(['./' + this.icons[btn].click], { skipLocationChange: false });
    // alert(btn);
  }
}
