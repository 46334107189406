<div class="label mt-4">预约专车(非接送机)</div>
<div class="sublabel">请至少提前半小时预约，我们将尽快帮您安排车辆</div>
<!-- {{rideOrder|json}} -->
<form #rideForm="ngForm" class=""   style="background-color: #FF659D ;" (ngSubmit)="submit(rideForm.value)" >
    <div class="row justify-content-around align-items-center toplabel">
        <div id="to_air" class="top_nav top_nav_active" ></div>
        <div id="from_air" class="top_nav"  ></div>
    </div>

    <div class="form-group">
        <div class="row">
            <div class="col-1"></div>
            <div class="col-9">
                <input [(ngModel)]="ridedate" type="text" placeholder="出发日期" readonly="readonly"
                    class="form-control bg-white" id="date" name="date" bsDatepicker [minDate]="minDate"
                    [bsConfig]="{ isAnimated: true,dateInputFormat: 'YYYY-MM-DD',containerClass:'theme-orange',showWeekNumbers: false }">
            </div>
            <div class="col-1">
                <img src="/assets/images/icon/calendar.png">
            </div>
        </div>
    </div>
    <div class="space"></div>
    <div class="form-group">
        <div class="row">
            <div class="col-1"></div>
            <div class="col-4 mr-0 pr-0">
                <select [(ngModel)]="ridehour" class="custom-select bg-white shadow " type="text" dir="rtl" id="ridehour" name="ridehour">
                    <option value=0 disabled>时</option>
                    <option>00</option>
                    <option>01</option>
                    <option>02</option>
                    <option>03</option>
                    <option>04</option>
                    <option>05</option>
                    <option>06</option>
                    <option>07</option>
                    <option>08</option>
                    <option>09</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
                    <option>21</option>
                    <option>22</option>
                    <option>23</option>

                </select>
            </div>
            <div class="col-1 pt-1">:</div>
            <div class="col-4 ml-0 pl-0">
                <select [(ngModel)]="ridemin" class="custom-select bg-white shadow" type="text" dir="ltr" id="ridemin" name="ridemin">
                    <option value=0 disabled>分</option>
                    <option>00</option>
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                </select>
            </div>
            <div class="col-1">
                <img src="/assets/images/icon/clock.png">
            </div>
        </div>
    </div>
    <div class="space"></div>
    <div class="form-group">
        <div class="row">
            <div class="col-1"></div>
            <div class="col-9">
                <select [(ngModel)]="rideperson" class="custom-select bg-white shadow" type="text" id="person" name="person">
                    <option value=0 disabled>请选择乘车人数</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                </select>
            </div>
            <div class="col-1">
                <img src="/assets/images/icon/group.png">
            </div>
        </div>
    </div>
    <div class="space"></div>

    <div class="form-group">
        <div class="row">
            <div class="col-1"></div>
            <div class="col-9 ">
                <button [disabled]="(ridedate=='')||(rideperson==0)" type="submit" class="btn bg-white shadow" style="color: #FF659D;"
                    >下一步</button>
            </div>

            <div class="col-1"></div>
        </div>
    </div>


</form>
<!-- <button class="btn bg-white shadow" [style.color]="active_to_air? '#1BA89D':'#008FB9' " (click)="showModal('title1234','body1234')" >Show Modal</button> -->
<app-modal [modal]="modal"></app-modal>
<!-- <app-modal ></app-modal>  -->
<app-loading [hidden]="loadingHidden"></app-loading>

