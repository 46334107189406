import { Component, OnInit } from '@angular/core';
import { SharedService } from "../shared.service";
import { Inject, Injectable } from '@angular/core';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Router,ActivatedRoute } from '@angular/router';
import { DbService } from '../db.service';
import { time } from 'console';
import { UserData } from '../user-data';
import { Flight } from '../flight';
import{Car} from '../car';
import { formatDate, DatePipe } from '@angular/common';
import { Airorder } from '../airorder';
import { stringify } from '@angular/compiler/src/util';
import { Const } from '../const';

@Component({
  selector: 'app-flight',
  templateUrl: './flight.component.html',
  styleUrls: ['./flight.component.css']
})

export class FlightComponent implements OnInit {
  constructor(private route:ActivatedRoute, private router: Router,private sharedService: SharedService,private dbService:DbService) {  }
  nav = {
    bk: 'book',
    fk: 'psg'
  };
  page:number;
  userdata:UserData;
  airOrder:Airorder ;
  title ="航班信息";
  
  // flight:Flight= JSON.parse(this.sharedService.getSession('flight')) ;
  timeOpt;
  sessionTime = this.sharedService.getSession('time');
  shareRide:boolean = true;
  active_to_air:boolean=true;
  datetime='';
  person='';
  
  carSelect:Array<boolean>=[false,false,false];
  cars=[new Car(0),new Car(1),new Car(2)];

  ngOnInit(): void {
    this.sharedService.updPage(2);
    this.sharedService.setSession('nav', JSON.stringify(this.nav));
    // this.sharedService.currentUserData.subscribe(user_data =>this.userdata=user_data);
    this.sharedService.currentPage.subscribe(page =>this.page=page);
    this.sharedService.currentAirOrder.subscribe(airOrder =>this.airOrder=airOrder);
    if ((this.airOrder.flight == null)) {
      this.router.navigate(['/']);
    }else{
      this.timeOpt = this.flightOption(this.airOrder.flight) ;
    }
  }

  flightOption(flight:Flight){
    var opt=Array();
    var roundEpoch = this.flightTimeRound(flight).getTime();
    for (let index = 20; index >= 0; index--) {
      opt.push([new Date(roundEpoch-index*30*60*1000), new Date(roundEpoch-index*30*60*1000+29*60*1000)]);      
    }
    return opt;
  }
  flightTimeRound(flight:Flight){
    var timezone = new Date().getTimezoneOffset();
    var depart_time_epoch = new Date(flight.depart_date).getTime()+ timezone*60*1000;
    var depart_obj = new Date(depart_time_epoch);
    depart_obj.setHours(parseInt(flight.depart_time.substr(0,2)));
    depart_obj.setMinutes(this.timeRound(parseInt(flight.depart_time.substr(3,2))));
    depart_obj.setTime(depart_obj.getTime()-1.5*60*60*1000);
    return depart_obj;
    // return depart_obj.getFullYear()+"-"+(depart_obj.getMonth()+1)+"-"+depart_obj.getDay()+"|"+depart_obj.getDate()+":"+depart_obj.getMinutes();
  }
  timeRound(minutes){
    return Math.floor(minutes/30)*30;
  }
  submit(){
    if (this.airOrder.flight.outbound==false) {
      this.airOrder.date=this.airOrder.flight.land_date;
      this.airOrder.time=this.airOrder.flight.land_time;
      this.airOrder.timerange = this.airOrder.flight.land_time;
    }
    if (this.airOrder.share)   this.airOrder.car=0;   
    this.airOrder.orig=this.airOrder.flight.outbound? 'KW':this.airOrder.flight.dest_port;
    this.airOrder.dest=this.airOrder.flight.outbound? this.airOrder.flight.orig_port : 'KW';
    this.sharedService.updAddFormValue(Const.DEFAULTADDFORM);
    this.checkPrice();
  }
  selectTimeSlot(){
    this.airOrder.date=this.datetime.substring(0,this.datetime.search(" "));
    this.airOrder.time=this.datetime.substring(this.datetime.search(" ")+1,this.datetime.search("~"));
    this.airOrder.timerange=this.datetime.substring(this.datetime.search(" ")+1);
  }
  // updAirOrder(){
  //   this.sharedService.updAir(this.airOrder);
  // }
  checkPrice(){
    let price=0;
    this.dbService.callPhp(this.airOrder,'checkprice.php')
    .subscribe(data=> {
      if(JSON.stringify(data)) price= parseInt(JSON.stringify(data));
      this.airOrder.price=price;
      this.sharedService.updAir(this.airOrder);
      this.nav.bk='flight';
      this.sharedService.setSession('nav', JSON.stringify(this.nav));
      this.router.navigate(['/psg'],{skipLocationChange:true});
    });
    ;
  }
  selectCar(carIndex:number){
    for (let index = 0; index < this.carSelect.length; index++) {
      if (index != carIndex) {
        this.carSelect[index]=false;
      } else {
        this.carSelect[index]=!this.carSelect[index];
      }
    }
    if (this.carSelect[carIndex] ) {
      this.airOrder.car=carIndex+1;
    }else    this.airOrder.car=0;
    this.sharedService.setSession('airorder',JSON.stringify(this.airOrder));
  }
  changePerson(){
    this.airOrder.addList=[];
  }
  
}
