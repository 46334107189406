<div class="container bg-white text-black  pt-2 pb-2">
    <div class="brief row">
        <div class="col-9 text-center">
            <div class="row mb-1">
                <div class="col-3 font-grey ">航班信息</div>
                <div class="col-9 info">{{airOrder.flight.ident_iata}} ({{airOrder.flight.orig_port}}-{{airOrder.flight.dest_port}})</div>
            </div>
            <div class="row mb-1">
                <div class="col-3 font-grey ">预约时间</div>
                <div class="col-5 info">{{airOrder.date}}</div>
                <div class="col-4 info">{{airOrder.timerange}}</div>
            </div>
            <div class="row mb-1">
                <div class="col-3 font-grey">乘车人数</div>
                <div class="col-5 info">{{airOrder.person}}人</div>
                <div class="col-4 info">{{sharefield}}</div>
            </div>
        </div>
        <div class="col-3 text-center ">
            <h3 class="mt-3 price">${{airOrder.price}}</h3>
            <div class="font-grey p-0"><u>价格及乘车说明</u></div>
        </div>
        
    </div>   
</div>

<!-- {{airOrder|json}} -->