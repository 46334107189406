import { Component, OnInit } from '@angular/core';
import { SharedService } from "../shared.service";
import { Router, ActivatedRoute } from '@angular/router';
import {UserData} from '../user-data';
import { DbService } from '../db.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor( private router: Router, private sharedService: SharedService, private dbService: DbService) { }

  ngOnInit(): void {
    if(parseInt(this.sharedService.getSession("countDown")) > 0) {
      this.countDownTimer = parseInt(this.sharedService.getSession("countDown"));
      this.countdown();
    }
    this.nav = JSON.parse(this.sharedService.getSession("nav"));
    if (this.sharedService.getSession("user")==null) {
      this.sharedService.setSession("user",JSON.stringify(this.user));
    }else     this.user = JSON.parse(this.sharedService.getSession("user"));
  }
  user:UserData= new UserData();
  nav;
  // bk="home";
  title ="验证手机号码";
  loadingHidden:boolean=true;
  sendBtn="发送验证码";
  countDownTimer:number;
  disableSend=false;
  modal = { show: false, title: '', body: '' };
  // private autoSaveInterval: number = window.setInterval(() => { console.log('123') }, 1000);

  sendCode(){
    // this.sharedService.setSession("countDown", 120);
    this.disableSend=true;
    this.countDownTimer=10;
    this.sharedService.setSession("countDown",this.countDownTimer);
    this.sendBtn = `重新发送(${this.countDownTimer}s)`;
    this.countdown();    
    this.dbService.callPhp(this.user, 'sendcode.php').subscribe(
      data=>{
        alert(JSON.stringify(data))
      }
    );
  }
  countdown(){
    this.disableSend = true;
    this.sendBtn = `重新发送(${this.countDownTimer}s)`;
    let c = window.setInterval(() => {
      this.countDownTimer = this.countDownTimer - 1;
      this.sharedService.setSession("countDown", this.countDownTimer);
      if (this.countDownTimer == 0) {
        this.sendBtn = `重新发送`;
        this.disableSend = false;
        clearInterval(c);
      }
      else {
        this.sendBtn = `重新发送(${this.countDownTimer}s)`;
      }
    }, 1000);
  }
  submit(){
    this.loadingHidden=false;
    this.sharedService.setSession("user", JSON.stringify(this.user));
    this.dbService.callPhp(this.user, 'verifycode.php').subscribe(
      data => {
        // alert(JSON.stringify(data));
        this.loadingHidden=true;
        switch (JSON.stringify(data)) {
          case '1':
            this.user.validStart=new Date().getTime();
            this.user.code=null;
            this.sharedService.setSession("user", JSON.stringify(this.user));
            this.router.navigate(['/'+this.nav.fk], { skipLocationChange: true });
            break;
          case '0':
            this.user.code = null;
            this.sharedService.setSession("user", JSON.stringify(this.user));
            this.modal.show=true;
            this.modal.title = "验证失败";
            this.modal.body = "";
            break;
          case '-1':
            // alert("expired")
            this.user.code = null;
            this.sharedService.setSession("user", JSON.stringify(this.user));
            this.modal.show = true;
            this.modal.title = "验证码已过期";
            this.modal.body = "";
            break;
          default:
            // alert(JSON.stringify(data));
            this.user.validStart = new Date().getTime();
            this.user.code = null;
            this.user.name = data.name;
            this.user.add=data.address;
            this.user.city=data.city;
            this.sharedService.setSession("user", JSON.stringify(this.user));
            this.router.navigate(['/' + this.nav.fk], { skipLocationChange: true });
            break;
        } 
        
      }
    );
  }

}
