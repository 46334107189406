import { Component, OnInit } from '@angular/core';
import { SharedService } from "../shared.service";
import { Router, ActivatedRoute } from '@angular/router';
import { Rideorder } from "../rideorder";

@Component({
  selector: 'app-form-ride',
  templateUrl: './form-ride.component.html',
  styleUrls: ['./form-ride.component.css']
})
export class FormRideComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private sharedService: SharedService) { 
    this.minDate = new Date();
    // this.minDate.setDate(this.minDate.getDate() - 1);
  }

  ngOnInit(): void {
    this.sharedService.currentRideOrder.subscribe(ride => this.rideOrder = ride)
  }
  minDate: Date;
  ridedate;
  ridehour=10;
  ridemin=30;
  rideperson=0;
  loadingHidden: boolean = true;
  modal = { show: false, title: '', body: '' };
  rideOrder: Rideorder= new Rideorder;

  submit(data){
    // alert(JSON.stringify(data));
    this.rideOrder.date = data.date.getFullYear() + '-' + this.sharedService.toMMDD((data.date.getMonth() + 1).toString()) + '-' + this.sharedService.toMMDD(data.date.getDate().toString());
    this.rideOrder.person=data.person;
    this.rideOrder.timehour=data.ridehour;
    this.rideOrder.timemin=data.ridemin;
    this.rideOrder.time=data.ridehour+":"+data.ridemin;
    // this.sharedService.updRide(this.rideOrder);
    this.sharedService.setSession("rideorder",JSON.stringify(this.rideOrder));
    this.router.navigate(['/ride'], { skipLocationChange: false });
  }
}
