<div  class="container text-center mb-5">
    <h4>填写重要备注信息</h4>

    <textarea [(ngModel)]="comm" class="form-control" row="15" name="comm" placeholder="特殊需求请在此注明. 如，是否需要准备儿童座椅(如不清楚请写明儿童身高，体重及年龄); 是否有异形行李 ..."></textarea>
    <div class="container tips text-left" >
        <div class="row">
            <div class="col-12">
                <div class="col-10 text-left tip_title mt-3"><img src="/assets/images/icon/airplane.png">温馨提示：</div>
                <div class="col-12 tip_body mt-2 mb-2">可接受现金及刷卡支付</div>
                <div *ngIf="share&&outbound" class="col-12 tip_body mt-2 mb-2">拼车送机默认可在<b style="color: black;">1小时</b>范围内调节出发时间</div>
                <div *ngIf="ride" class="col-12 tip_body mt-2 mb-2">中途不提供带物或顺道上下客服务</div>
                <div *ngIf="share&&!outbound" class="col-12 tip_body mt-2 mb-2">拼车接机默认拼接实际到达时间<b style="color: black;">1小时</b>内的航班</div>
                
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <input [(ngModel)]="check" class="col-1 btn form-check-input mt-3" type="checkbox" value="" id="check"
                name=shareRide required>
                <label class="col-10 btn form-check-label text-left ml-2 mt-1" for="check" data-container="body" data-toggle="popover" data-placement="top" [style.color]="share? '#1BA89D':'#006158' ">我已阅读并同意以上条款</label>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <input [(ngModel)]="usepoint" class="col-1 btn form-check-input mt-3" type="checkbox" value="" id="usepoint"
                    name=shareRide required>
                <label class="col-10 btn form-check-label text-left ml-2 mt-1" for="usepoint" data-container="body"
                    data-toggle="popover" data-placement="top" [style.color]="share? '#1BA89D':'#006158' ">使用当前积分抵可扣车费<b style="color: red;">$10</b></label>
            </div>
        </div>
    </div>
    <div *ngIf="!ride">
        <button [disabled]="!check" class="btn mt-3" [style.background-color]="share? '#1BA89D':'#006158' " (click)="submit()" >确认并提交订单</button>
    </div>
    
    <div *ngIf="ride">
        <button [disabled]="!check" class="btn mt-3" [style.background-color]=" '#FF659D' "
            (click)="submit()">确认并提交订单</button>
    </div>
    <div class="mt-5 mb-5" style="height: 30px;"> </div>
</div>

