import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { Address } from '../address';
import { SharedService } from "../shared.service";
import { Airorder } from '../airorder';
import { Rideorder } from '../rideorder';

@Component({
  selector: 'add-brief',
  templateUrl: './add-brief.component.html',
  styleUrls: ['./add-brief.component.css']
})
export class AddBriefComponent implements OnInit {

  constructor(private sharedService: SharedService) { }

  ngOnInit(): void {
    // this.sharedService.currentAirOrder.subscribe(airOrder =>this.airOrder=airOrder);
    // this.sharedService.currentAddFormValue.subscribe(addFormValue =>this.addFormValue=addFormValue);
  }
  @Input() dest:boolean =false;
  @Input() addList:Array<Address>;
  @Output() changeIndex=new EventEmitter();
  // airOrder:Airorder;
  addFormValue;

  // @Output() addUpd = new EventEmitter();
  // index2Upd:number;

  change(i:number){
    this.changeIndex.emit(i);

  }
}
