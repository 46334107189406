export class Const {
    public static AIRPORT=['CYYZ','CYTZ','CYHM','CYKF'];
    public static KWREGION =['Waterloo','Kitchener'];
    public static LOGINVALISECOND = 60;
    public static CARCLASS =['classA','classB','classC','classD',];
    public static CARNAME=['豪华SUV','7座商务车','豪华商务车','豪华中巴'];
    public static CARCAP =[4,6,7,11];
    public static CARBIG =[2,5,8,20];
    public static CARSMALL =[2,3,2,20];
    public static CARCOMF =[4,4,5,4];
    public static CARPIC =['','','',''];


    public static CARB={
        'type':'B',
        'name':'本田豪华商务',
        'cap': 6,
        'big':5,
        'small':3,
        'comf':4,
        'pic0':'',
        'pic1':'',
        'pic2':'',
        'pic3':''
    };
    public static CARC={
        'type':'C',
        'name':'奔驰豪华商务',
        'cap': 7,
        'big':8,
        'small':2,
        'comf':5,
        'pic0':'',
        'pic1':'',
        'pic2':'',
        'pic3':''
    };
    public static DEFAULTADDFORM:Const={
        "address":"",
        "city":"",
        "province":"",
        "name":"",
        "tel":"",
        "cc":"0",
        "person":0,
        "big":0,
        "small":0,
        "p_left":[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
      };
}
