<div class="container m-0" [class.air_select]="air_select" [class.ride_select]="ride_select" [class.tour_select]="tour_select" >
    <div class="col-12 p-0"><img src="/assets/images/car/b0.jpg"></div>
    <div class="col-12 pl-1 pr-0 name">{{car.name}}</div>
    <div class="row content pt-0">
        <div class="col-6  pl-1 pr-0 ">最大载客数:</div>
        <div class="col-6  pl-1 pr-0 text-left">{{car.cap}}人</div>
    </div>
    <div class="row content">
        <div class="col-6 pl-1 pr-0">最大行李数:</div>
        <div class="col-6 pl-1 pr-0 text-left">{{car.big}}大{{car.small}}小</div>
    </div>
    <div class="row content">
        <div class="col-6 pl-1 pr-0">乘坐舒适度:</div>
        <div class="col-6 pl-1 pr-0 text-left">
            <rating [(ngModel)]="car.comf" [max]="max" [readonly]="isReadonly" s></rating>
    
        </div>
    </div>

</div>
<!-- {{car|json}} -->