export class Address {
    add:string='';
    city:string='';
    province:string ='';
    name:string='';
    tel:number=0;
    person:number=0;
    big:number=0;
    small:number=0;
}
