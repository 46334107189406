import { Component, OnInit } from '@angular/core';
import { SharedService } from "../shared.service";
import { Router,ActivatedRoute } from '@angular/router';
import { DbService } from '../db.service';
import {Flight} from '../flight';
import {Airorder} from "../airorder";
import {Const} from '../const';
import { UserData } from '../user-data';
import { from } from 'rxjs';

@Component({
  selector: 'app-form-air',
  templateUrl: './form-air.component.html',
  styleUrls: ['./form-air.component.css']
})
export class FormAirComponent implements OnInit {
  minDate: Date;
  page:number;
  flightdate=new Date("2021-04-26T00:00:00.000Z");
  flightno='hu7976';
  active_to_air:boolean=true;
  loadingHidden:boolean=true;
  modal={show:false,title:'',body:''};
  airOrder:Airorder;
  constructor(private route:ActivatedRoute,private router: Router,private sharedService: SharedService,private dbService:DbService) { 
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 1);
  }

  ngOnInit(): void {
    this.sharedService.currentAirOrder.subscribe(air =>this.airOrder=air)
   }
  // myalert=new AlertMsg();
  // public currentPage =2;
  // flight:Flight =new Flight();
  // airOrder:Airorder = new Airorder();
  
  public icon_flight:string ="/assets/images/icon/takeoff.png";
  public act(action:number){
    if(action==0){
      this.active_to_air=true;
      this.icon_flight="/assets/images/icon/takeoff.png";
    }else{
      this.active_to_air=false;
      this.icon_flight="/assets/images/icon/landing.png";
    }
  }
  
  chkflight(data:any){
    this.loadingHidden=false;
    this.airOrder.flight= new Flight();
    this.airOrder.flight.depart_date=data.date.getFullYear()+'-'+(data.date.getMonth()+1)+'-'+data.date.getDate();
    this.airOrder.flight.ident_iata=data.flightno.toUpperCase();
    this.airOrder.flight.outbound = this.active_to_air;
    // alert(JSON.stringify(this.airOrder.flight));
    this.dbService.callPhp(this.airOrder.flight,'checkflight.php')
    .subscribe(
      data => {
        // alert(JSON.stringify(data));
        this.loadingHidden=true;
        if (data.error ==0) {
          this.bldFlight(this.airOrder.flight,data);
          this.sharedService.updFlight(this.airOrder.flight);
          this.sharedService.updAir(this.airOrder);
          this.router.navigate(['/flight'],{skipLocationChange:true});
        } else {
          alert((data.err_msg+data.flightno));
        }   
      }
    )
    // this.sharedService.setSession('flight',JSON.stringify(this.flight));
  }

  bldFlight(f:Flight,data:any){
    this.airOrder.flight.depart_date=data.depart_date;
    // this.flight.ident_iata=data.ident;
    this.airOrder.flight.ident_icao=data.ident;
    this.airOrder.flight.land_date=data.arrival_date;
    this.airOrder.flight.depart_time=data.depart_time;
    this.airOrder.flight.land_time=data.arrival_time;
    this.airOrder.flight.orig_city=data.orig_city;
    this.airOrder.flight.dest_city=data.dest_city;
    this.airOrder.flight.orig_port=data.orig_port;
    this.airOrder.flight.dest_port=data.dest_port;
    
    return this.airOrder.flight;
  }

  showModal(title:string,body:string){
    // alert(JSON.stringify(this.modal));
    this.modal.show=true;
    this.modal.title=title;
    this.modal.body=body;
    // this.sharedService.updModal(this.modal);
  }
}
