import { Component, ViewChild,Input,OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
// import { SharedService } from "../shared.service";
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent {
  @ViewChild('autoShownModal', { static: true }) autoShownModal: ModalDirective;
  @Input() modal:any;
  // modal:any={show:false,title:'',body:''};

  ngOnInit():void {
    // this.modal=this.sharedService.getModal()
  }
  // constructor(private sharedService: SharedService) {   }
  isModalShown = false;
 
  showModal(): void {
    this.isModalShown = true;
  }
 
  hideModal(): void {
    this.autoShownModal.hide();
  }
 
  onHidden(): void {
    this.modal.show = false;
    this.modal.title='';
    this.modal.body='';
    // alert(JSON.stringify(this.modal));
  }
}
