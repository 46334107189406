import { Component, OnInit } from '@angular/core';
import { SharedService } from "../shared.service";

@Component({
  selector: 'app-my',
  templateUrl: './my.component.html',
  styleUrls: ['./my.component.css']
})
export class MyComponent implements OnInit {
  page:number;
  constructor(private sharedService: SharedService) { }
  ngOnInit(): void {
    this.sharedService.updPage(5);
  }

}
