import { Component, OnInit } from '@angular/core';
import { SharedService } from "../shared.service";
import { Router, ActivatedRoute } from '@angular/router';
import { Rideorder } from "../rideorder";
import { Car } from '../car';
import { Address } from '../address';
import { UserData } from '../user-data';
import { Const } from '../const';
import { exit } from 'process';
import { stringify } from '@angular/compiler/src/util';

@Component({
  selector: 'app-ride',
  templateUrl: './ride.component.html',
  styleUrls: ['./ride.component.css']
})
export class RideComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.sharedService.updPage(2);
    
    if (this.sharedService.getSession("rideorder")==null) {
      this.router.navigate(['/book'], { skipLocationChange: false });
      return;
    }else {
      this.rideOrder = JSON.parse(this.sharedService.getSession("rideorder"));
      this.updPLeft();
      // this.addForm.name=this.user.name;
      // this.addForm.cc=stringify(this.user.cc);
      // this.addForm.tel=stringify(this.user.tel);
      // this.addForm.address=this.user.add;
      // this.addForm.city=this.user.city;
      // this.addForm.province=this.user.province;
    }
    
    if (JSON.parse(this.sharedService.getSession('user')) != null) {
      this.user = JSON.parse(this.sharedService.getSession('user'));
    }
    if ((new Date().getTime()) - (this.user.validStart) > Const.LOGINVALISECOND * 1000) {
      this.nav.fk= "ride";
      this.sharedService.setSession('nav',JSON.stringify(this.nav));
      this.router.navigate(['/login'], { skipLocationChange: true });
    } else {
      this.rideOrder.contact_name = this.user.name;
      this.rideOrder.contact_tel = this.user.cc + this.user.tel;
    }
    if (this.rideOrder.origList.length == 0) {
      this.addForm.name = this.user.name;
      this.addForm.cc = this.user.cc.toString();
      if (this.user.tel != 0) {
        this.addForm.tel = this.user.tel.toString();
      }

      this.addForm.address = this.user.add;
      this.addForm.city = this.user.city;
      // this.addForm.province=this.user.province;
    }
    // this.nav = JSON.parse(this.sharedService.getSession('nav'));
    // this.sharedService.currentRideOrder.subscribe(ride => this.rideOrder = ride)
    
  }
  rideOrder:Rideorder;
  title = "选择车辆";
  nav={bk:"book",fk:"",keepUrl:false,step:0};
  step=1;
  user: UserData = new UserData();
  loadingHidden: boolean = true;
  modal = { show: false, title: '', body: '' };
  carSelect: Array<boolean> = [false, false, false];
  cars = [new Car(0), new Car(1), new Car(2)];
  addForm = {
    "address": "",
    "city": "",
    "province": "ON",
    "name": "",
    "tel": "",
    "cc": "0",
    "person": 0,
    "big": -1,
    "small": -1,
    "p_left": []
  };

  selectCar(carIndex: number) {
    for (let index = 0; index < this.carSelect.length; index++) {
      if (index != carIndex) {
        this.carSelect[index] = false;
      } else {
        this.carSelect[index] = !this.carSelect[index];
      }
    }
    if (this.carSelect[carIndex]) {
      this.rideOrder.car = carIndex + 1;
    } else this.rideOrder.car = 0;
    this.sharedService.setSession('airorder', JSON.stringify(this.rideOrder));
  }
  setCar(car){
    this.rideOrder.car=car;
  }
  next(){
    this.nav.step +=1;
    if (this.nav.step > 0) this.nav.keepUrl = true;
  }
  back(){
    this.nav.step -=1;
    if (this.nav.step ==0) this.nav.keepUrl=false;
  }
  updPLeft() {
    this.addForm.p_left = [];
    let p_add = 0;
    if (this.rideOrder.origList.length > 0) {
      this.rideOrder.origList.forEach(element => {
        p_add += parseInt(element.person.toString());
      });
    }
    let p = this.rideOrder.person - p_add;
    for (let index = 0; index < p; index++) {
      this.addForm.p_left.push(index + 1);

    }
  }
  resetAddForm() {
    this.addForm = {
      "address": "",
      "city": "",
      "province": "ON",
      "name": "",
      "tel": "",
      "cc": "0",
      "person": 0,
      "big": -1,
      "small": -1,
      "p_left": []
    };
    this.updPLeft();
  }
  newOrig(form){
    let add =new Address();
    add.add = this.sharedService.cap(form.address.trim());
    add.city = this.sharedService.cap(form.city.trim());
    add.province = form.province.toUpperCase();
    add.name = this.sharedService.cap(form.name);
    add.tel = (form.cc + form.tel);
    add.person = (form.person);
    add.big = (form.big);
    add.small = (form.small);
    this.rideOrder.origList.push(add);
    this.resetAddForm();
    this.updPLeft();
    if ((this.addForm.p_left.length == 0) && (this.rideOrder.destList.length >0)) {
      this.nav.step=3;
    }
  }
  newDest(form) {
    let add = new Address();
    add.add = this.sharedService.cap(form.address.trim());
    add.city = this.sharedService.cap(form.city.trim());
    add.province = form.province.toUpperCase();
    this.rideOrder.destList.push(add);
    this.resetAddForm();
    // this.next();
  }
  changeOrig(index){
    this.setOrigForm(index);
    this.delOrig(index);
    this.updPLeft();
    this.nav.step=1;
  }
  changeDest(index){
    this.setDestForm(index);
    this.delDest(index);
    this.updPLeft();
    this.nav.step = 2;
  }
  setOrigForm(index) {
    this.addForm = {
      "address": this.rideOrder.origList[index].add,
      "city": this.rideOrder.origList[index].city,
      "province": this.rideOrder.origList[index].province,
      "name": this.rideOrder.origList[index].name,
      "tel": this.sharedService.tel2cc(this.rideOrder.origList[index].tel.toString())[1],
      "cc": this.sharedService.tel2cc(this.rideOrder.origList[index].tel.toString())[0],
      "person": this.rideOrder.origList[index].person,
      "big": this.rideOrder.origList[index].big,
      "small": this.rideOrder.origList[index].small,
      "p_left": []
    };
  }
  setDestForm(index) {
    this.addForm = {
      "address": this.rideOrder.destList[index].add,
      "city": this.rideOrder.destList[index].city,
      "province": this.rideOrder.destList[index].province,
      "name": "",
      "tel": '',
      "cc": "",
      "person": 0,
      "big": 0,
      "small": 0,
      "p_left": []
    };
  }
  delOrig(index) {
    this.rideOrder.origList.splice(index, 1);
  }
  delDest(index) {
    this.rideOrder.destList.splice(index, 1);
  }
}
