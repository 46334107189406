<!-- <button type="button" class="btn btn-primary" (click)="showModal()">Render auto-shown modal</button>
<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal"
     class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog ">
    <div class="modal-content ">
      <div class="modal-header">
        <h4 id="dialog-auto-name" class="modal-title pull-left">{{modal.title}}</h4> -->
        
        <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button> -->
      <!-- </div> -->
      <!-- <div class="modal-body">
        {{modal.body}}
      </div> -->
    <!-- </div>
  </div>
</div> -->


<div *ngIf="modal.show" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal"
     class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog ">
    <div class="modal-content ">
      <div class="modal-header">
        <h4 id="dialog-auto-name" class="modal-title pull-left">{{modal.title}}</h4>
        
      </div>
      <div class="modal-body">
        {{modal.body}}
      </div>
    </div>
  </div>
</div>