import { Component, OnInit } from '@angular/core';
import { SharedService } from "../shared.service";
@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.css']
})
export class PriceComponent implements OnInit {
  page:number;
  constructor(private sharedService: SharedService) { }
  ngOnInit(): void {
    this.sharedService.updPage(3);
  }
  form={'p_left':3};
  dest;



  select:boolean=true;

}
