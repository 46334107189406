// import { Flight } from "./flight";
export class UserData {
    // flight:Flight;
    name: string='';
    tel: number=0;
    cc: number=0;
    code: number;
    validStart: number=0;
    add:string='';
    city:string='';
    province:string='ON';
}
