<!-- {{airOrder|json}}<br> -->
<app-head [bk]="nav.bk" [title]="title"></app-head>
<!-- {{userdata|json}} -->
<!-- <div class="container text-center my-2">
    <div class="row">
        <div class="col-1" name="back"><img src="/assets/images/icon/back.png"></div>
        <div class="col-10 align-items-center"><h5>航班信息</h5></div>
        <div class="col-1" ></div>
    </div>
    
</div> -->
<div class="container shadow justify-content-center flight_box">
    <div class="col-12 text-center pt-2 flight_no">{{airOrder.flight.ident_iata}} </div>
    <div class="row">
        <div class="col-5 text-right">
        <div class="cell">
            <div class="cell_label">起飞日期(当地)</div>
            <div class="cell_content">{{airOrder.flight.depart_date}}</div>
        </div>
        <div class="cell">
            <div class="cell_label">起飞时间(当地)</div>
            <div class="cell_content">{{airOrder.flight.depart_time}}</div>
        </div>
        <div class="cell">
            <div class="cell_label">起飞城市</div>
            <div class="cell_content">{{airOrder.flight.orig_city}}</div>
        </div>
        <div class="cell">
            <div class="cell_label">起飞机场</div>
            <div class="cell_content">{{airOrder.flight.orig_port}}</div>
        </div>
    </div>
    <div class="col-1 text-center align-self-center icon"><img src="/assets/images/icon/airplane.png"></div>
        <div class="col-5 text-left">
            <div class="cell">
                <div class="cell_label">到达日期(当地)</div>
                <div class="cell_content">{{airOrder.flight.land_date}}</div>
            </div>
            <div class="cell">
                <div class="cell_label">到达时间(当地)</div>
                <div class="cell_content">{{airOrder.flight.land_time}}</div>
            </div>
            <div class="cell">
                <div class="cell_label">到达城市</div>
                <div class="cell_content">{{airOrder.flight.dest_city}}</div>
            </div>
            <div class="cell">
                <div class="cell_label">到达机场</div>
                <div class="cell_content">{{airOrder.flight.dest_port}}</div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="airOrder.flight.outbound" class="container tips text-center" id="tips">
    <div class="row">
        <div class="col-12">
            <div class="col-10 text-left tip_title"><img src="/assets/images/icon/airplane.png">温馨提示：</div>
            <div class="col-12 tip_body">加拿大境外航班建议提前<b style="color: black;">3小时</b>到达机场</div>
            <div class="col-12 tip_body">加拿大境内航班建议提前<b style="color: black;">2小时</b>到达机场</div>
            <div class="col-12 tip_body">滑铁卢至皮尔逊机场通常需要<b style="color: black;">1~1.5小时 </b>  </div>
        </div>
    </div> 
</div>
<div class="container text-center">
    <form #flightconfirm = "ngForm" (ngSubmit)="submit()">
        <select *ngIf="airOrder.flight.outbound" [(ngModel)]="datetime" (change)="selectTimeSlot()" class="custom-select bg-white shadow my-3" id="time" name="time">
            <option value="" disabled >请选择出发时段</option>
            <!-- value="{{opt[0]|date:'yyyy-MM-dd HH:mm'}}" -->
            <option *ngFor="let opt of timeOpt" >{{opt[0]|date:'yyyy-MM-dd HH:mm'+'~'+ (opt[1]|date:'HH:mm')}}</option>
            
        </select>
        <select [(ngModel)]="airOrder.person" class="custom-select bg-white shadow my-3" type="text" id="person" name="person" (change)="changePerson()">
            <option value=0 disabled>请选择乘车人数</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
        </select>
        <div class="form-check row text-left">
            <input [(ngModel)]="airOrder.share" class="col-1 btn form-check-input mt-3" type="checkbox" value="" id="defaultCheck1" name= shareRide checked >
            <label class="col-7 btn form-check-label text-left ml-4" for="defaultCheck1" data-container="body" data-toggle="popover" data-placement="top">
                接受与他人拼车
                <button type="button" class="col-2 btn bg-white text-primary" popover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus.">
                ?
                </button>
            </label> 
            
        </div>
        <div *ngIf="airOrder.share==false" class="row" >
            <div *ngFor="let car of cars; let i = index" class="col-4 p-1" (click)="selectCar(i)"> 
                <car-brief [air_select]="carSelect[i]" [car]="car"></car-brief>
            </div>
        </div>

        <button [disabled]="(((airOrder.person==0)||(datetime==''))&&((airOrder.flight.outbound)||(airOrder.person==0)))||(airOrder.share==false&&airOrder.car ==0)" type="submit" class="btn shadow my-3" [style.background-color]="airOrder.flight.outbound? '#1BA89D':'#008FB9' "  >下一步</button>
    </form>
</div>
<!-- {{datetime}}<br>
{{airOrder|json}} -->
<div class="space"></div>
<div class="space mb-5"></div>
<app-navbar [page]="2"></app-navbar>
<!-- {{nav|json}} -->
