<!-- <div class="container">
  <app-carousel [images]="images" ></app-carousel>

</div>

<div class="small_screen">
  <drag-scroll>
    <img drag-scroll-item src="https://lettgotravel.com/images/uploads/big/1.jpg" (click)="test(1)" />
    <img drag-scroll-item src="https://lettgotravel.com/images/uploads/big/2.jpg" (click)="test(2)"/>
    <img drag-scroll-item src="https://lettgotravel.com/images/uploads/big/3.jpg" (click)="test(3)"/>
  </drag-scroll>
</div>
<div class="big_screen">
  <drag-scroll scrollbar-hidden="true">
    <img drag-scroll-item src="https://lettgotravel.com/images/uploads/big/1.jpg" (click)="test(4)" />
    <img drag-scroll-item src="https://lettgotravel.com/images/uploads/big/2.jpg" (click)="test(5)"/>
    <img drag-scroll-item src="https://lettgotravel.com/images/uploads/big/3.jpg" (click)="test(6)"/>
  </drag-scroll>
</div> -->

<!-- <app-navbar></app-navbar> -->
<router-outlet></router-outlet>
<!-- <a class="button" routerLink="/about">Heroes</a> -->
  <!-- <button (click)="moveLeft()">Left</button> -->
