import { Component,ViewChild } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css',]
})
export class AppComponent {
  // @ViewChild(CarouselComponent) carousel: CarouselComponent;
  title = 'LettGoTravel';
  myCarousel;
  public carouselWidth = 640;
  public current =0;
  carouselHeight = 220;
  // public slides = [
  //   {src: 'https://lettgotravel.com/images/uploads/big/1.jpg'},
  //   {src: 'https://lettgotravel.com/images/uploads/big/2.jpg'},
  //   {src: 'https://lettgotravel.com/images/uploads/big/3.jpg'}
  // ];
  public images=[
    {src: "https://lettgotravel.com/images/uploads/big/1.jpg"},
    {src: 'https://lettgotravel.com/images/uploads/big/2.jpg'},
    {src: 'https://lettgotravel.com/images/uploads/big/3.jpg'}
  ];
  slideImages=[
    { image: 'https://cdn.vox-cdn.com/uploads/chorus_image/image/56748793/dbohn_170625_1801_0018.0.0.jpg', title: 'The first slide', href: '#config' },
    { image: 'https://cdn.vox-cdn.com/uploads/chorus_asset/file/9278671/jbareham_170917_2000_0124.jpg', clickAction: () => alert('custom click function') },
    { image: 'https://cdn.vox-cdn.com/uploads/chorus_image/image/56789263/akrales_170919_1976_0104.0.jpg', caption: 'Apple TV', href: 'https://www.apple.com/' }
  ];

  // slides=['https://lettgotravel.com/images/uploads/big/1.jpg',
  //  'https://lettgotravel.com/images/uploads/big/2.jpg',
  //  'https://lettgotravel.com/images/uploads/big/3.jpg'];
  

  test(num){
    alert(num);
  }
}
