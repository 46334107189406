import { Component, OnInit } from '@angular/core';
import { SharedService } from "../shared.service";
@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.css']
})
export class TourComponent implements OnInit {
  page:number;
  constructor(private sharedService: SharedService) { }
  ngOnInit(): void {
    this.sharedService.updPage(1);
  }

}
