import { Address } from "./address";
import { Flight } from "./flight";

export class Airorder {
    date:string='';
    time:string= '';
    timerange:string='';
    flight:Flight;
    share:boolean=true;
    orig:string='';
    dest:string='';
    price:number=0;
    driver:number=0;
    car:number=0;
    contact_name:string='';
    Contact_tel:number=0;
    person:number=0;
    luggage_large:number=0;
    luggage_small:number=0;
    user_comm:string='';
    addList:Array<Address>=[];
}
