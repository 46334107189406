
<nav class="navbar navbar-light bg-light fixed-bottom justify-content-center shadow-lg">
    <form class="justify-content-center">
      <ng-container *ngFor="let img of icons; let i = index">
        <div *ngIf="i === page;then currentButton; else other_buttons" ></div>

          <!-- <button  -->
          <!-- *ngIf="i === currentPage;else other_bottons" -->
          <!-- class="btn col-2 current" type="button"><img [src]="img.icon"><br>{{img.name}}</button> -->
          <ng-template #currentButton><button class="btn col-2 current" type="button" >
            <!-- <a [routerLink]="img.click"> -->
              <img [src]="img.icon"><br>{{img.name}}
            <!-- </a> -->
          </button></ng-template>
          <ng-template #other_buttons><button class="btn col-2" type="button"  >
            <a [routerLink]="'/'+img.click">
              <img [src]="img.icon"><br>{{img.name}}
            </a>
          </button></ng-template>
          <!-- {{i}} -->
      </ng-container>
      
    </form>
  </nav>
  <!-- {{currentPage}} -->