import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
// import * as EventEmitter from 'events';
import { Car } from '../car';
@Component({
  selector: 'car-select',
  templateUrl: './car-select.component.html',
  styleUrls: ['./car-select.component.css']
})
export class CarSelectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  @Input() color;
  @Input() car_select;
  @Output() selectCar=new EventEmitter();
  carSelect: Array<boolean> = [false, false, false];
  cars = [new Car(0), new Car(1), new Car(2)];


  select(carIndex: number) {
    // alert(carIndex);
    for (let index = 0; index < this.car_select.length; index++) {
      if (index != carIndex) {
        this.car_select[index] = false;
      } else {
        this.car_select[index] = !this.car_select[index];
      }
    }
    if (this.car_select[carIndex]) {
      this.selectCar.emit(carIndex + 1);
    } else this.selectCar.emit(0);
  }

}
