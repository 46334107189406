import { from } from "rxjs";
import {Const} from './const';
export class Car {
    type:string='';
    name:string='';
    cap: number=0;
    big:number =0;
    small:number=0;
    comf:number=0;
    pic0:string='';
    pic1:string='';
    pic2:string='';
    pic3:string='';
    constructor(num: number){
        this.type=Const.CARCLASS[num];
        this.name=Const.CARNAME[num];
        this.cap=Const.CARCAP[num];
        this.big=Const.CARBIG[num];
        this.small=Const.CARSMALL[num];
        this.comf=Const.CARCOMF[num];



        
      }
}
