import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { TourComponent } from './tour/tour.component';
import { BookComponent } from './book/book.component';
import { AboutComponent } from './about/about.component';
import { PriceComponent } from './price/price.component';
import { MyComponent } from './my/my.component';
import { FlightComponent } from './flight/flight.component';
import { PsgInputComponent } from './psg-input/psg-input.component';
import { LoginComponent} from './login/login.component';
import { RideComponent } from './ride/ride.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'tour', component: TourComponent },
  { path: 'book', component: BookComponent },
  { path: 'price', component: PriceComponent },
  { path: 'about', component: AboutComponent },
  { path: 'my', component: MyComponent },
  { path: 'flight', component: FlightComponent },
  { path: 'psg', component: PsgInputComponent },
  { path: 'login', component: LoginComponent },
  { path: 'ride', component: RideComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{onSameUrlNavigation:'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
