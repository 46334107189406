import { Component, OnInit } from '@angular/core';
import { Flight } from '../flight';
import { Airorder } from '../airorder';
import { UserData } from '../user-data';
import { SharedService } from "../shared.service";
import { Router,ActivatedRoute } from '@angular/router';

@Component({
  selector: 'air-brief',
  templateUrl: './air-brief.component.html',
  styleUrls: ['./air-brief.component.css']
})
export class AirBriefComponent implements OnInit {

  constructor(private route:ActivatedRoute, private router: Router,private sharedService: SharedService) {  }

  ngOnInit(): void {
    this.sharedService.currentAirOrder.subscribe(airOrder =>this.airOrder=airOrder);
    this.sharefield=this.airOrder.share?"可拼车":"专车";
    if (this.airOrder.flight==null) {
      this.router.navigate(['/book']);
    }
  }
  // flight:Flight= JSON.parse(this.sharedService.getSession('flight')) ;
  airOrder:Airorder ;
  sharefield;

}
