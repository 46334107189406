
<div class="label mt-4">接送机</div>
<div class="sublabel">请提前24小时在此预约，小于24小时只能人工预订</div>
<form #flightForm="ngForm" class="" [style.background-color]="active_to_air? '#1BA89D':'#008FB9' " (ngSubmit)="chkflight(flightForm.value)">
    <div class="row justify-content-around align-items-center toplabel">
        <div id="to_air" class="top_nav top_nav_active" [class.top_nav_active]="active_to_air"   (click)="act(0)">送机</div>
        <div id="from_air" class="top_nav" [class.top_nav_active]="!active_to_air" (click)="act(1)">接机</div>
    </div>

  <div class="form-group" >
    <div class="row" >
        <div class="col-1"></div>
        <div class="col-9" >
            <input [(ngModel)]="flightdate" type="text" placeholder="航班日期" readonly="readonly" class="form-control bg-white" id="date" name="date" bsDatepicker [minDate]="minDate" [bsConfig]="{ isAnimated: true,dateInputFormat: 'YYYY-MM-DD',containerClass:'theme-orange',showWeekNumbers: false }">
        </div>
        <div class="col-1" >
            <img src="/assets/images/icon/calendar.png">
        </div>
    </div>
  </div>
  <div class="space"></div>
  <div class="form-group">
    <div class="row">
        <div class="col-1"></div>
        <div class="col-9">
            <input [(ngModel)]="flightno" type="text" placeholder="航班号,例如AC31" class="form-control" id="flightno" name="flightno">
        </div>
        <div class="col-1" >
            <img [src]="icon_flight">
        </div>
    </div>
  </div>
  <div class="space"></div>
  <!-- <div class="form-group">
    <div class="row">
        <div class="col-1"></div>
        <div class="col-9">
            <input [(ngModel)]="toair"type="text" class="form-control" id="toair" [(value)]="active_to_air" name="toair">
        </div>
        <div class="col-1" >
        </div>
    </div>
  </div> -->
  <div class="form-group">
    <div class="row">
        <div class="col-1"></div>
        <div class="col-9 " >
            <button [disabled]="(flightdate==null)||(flightno=='')" type="submit" class="btn bg-white shadow" [style.color]="active_to_air? '#1BA89D':'#008FB9' "  >下一步</button>
        </div>
        
        <div class="col-1"></div>
    </div>
  </div>
  
  
</form>
<!-- <button class="btn bg-white shadow" [style.color]="active_to_air? '#1BA89D':'#008FB9' " (click)="showModal('title1234','body1234')" >Show Modal</button> -->
<app-modal [modal]="modal"></app-modal>
<!-- <app-modal ></app-modal>  -->
<app-loading [hidden]="loadingHidden"></app-loading>
<!-- {{airOrder|json}} -->