import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DbService {

  constructor(private _http: HttpClient) { }
  phpbase = window.location.protocol + '//' + window.location.host.replace(":4200","/LettGoTravel/src")+'/api/php/';
  // phpbase = 'http://localhost/LettGoTravel/src/assets/php/';

  callPhp(postdata:any,phpfile:string){
    // alert(this.phpbase+phpfile);
    return this._http.post<any>(this.phpbase+phpfile,postdata,{
      headers : {
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    })
  }
}
