import { Component, OnInit } from '@angular/core';
import { SharedService } from "../shared.service";

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.css']
})
export class BookComponent implements OnInit {
  page:number;
  constructor(private sharedService: SharedService) { }
  ngOnInit(): void {
    // this.sharedService.currentPage.subscribe(message => this.page = message)
    this.sharedService.updPage(2);
    this.sharedService.setSession('nav',JSON.stringify({bk:'',fk:''}));
    this.nav=JSON.parse(this.sharedService.getSession('nav'));
    // this.sharedService.resetAddFormValue();
    // this.sharedService.currentPage.subscribe(page =>this.page=2)
  }
  nav ;
  // public currentPage =2;
}
