import { stringify } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
// import { DragScrollComponent } from 'ngx-drag-scroll';
import { SharedService } from "../shared.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  page:number;
  constructor(private sharedService: SharedService) { }
  ngOnInit(): void {
    this.sharedService.updPage(0);
    this.sharedService.setSession('nav',JSON.stringify(this.nav));
  }
  public images=[
    {src: "https://lettgotravel.com/images/uploads/big/1.jpg"},
    {src: 'https://lettgotravel.com/images/uploads/big/2.jpg'},
    {src: 'https://lettgotravel.com/images/uploads/big/3.jpg'}
  ];
  nav= {
    bk:'home',
    fk:''
  };
  test(num){
    alert(num);
  }
  // ngAfterViewInit():void{
  //   this.sharedService.updPage(0);
  // }
}
