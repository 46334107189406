import { CloneVisitor } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, OnInit,Input } from '@angular/core';
import{Car} from '../car';

@Component({
  selector: 'car-brief',
  templateUrl: './car-brief.component.html',
  styleUrls: ['./car-brief.component.css']
})
export class CarBriefComponent implements OnInit {
  @Input() ride_select:boolean;
  @Input() air_select: boolean;
  @Input() tour_select: boolean;
  // @Input() color;
  @Input() car:Car;
  constructor() { }

  ngOnInit(): void {
    // alert("here");
    // switch (this.color) {
    //   case "ride":
    //     this.ride_select = this.select;
    //     break;
    //   case "air":
    //     this.ride_select = this.select;
    //     break;
    //   case "tour":
    //     this.ride_select = this.select;
    //     break;
    //   default:
    //     break;
    // }
  }
  // ride_select:boolean=false;
  // air_select:boolean=false;
  // tour_select: boolean = false;
  // style_class = "";
  // color_select;
  max: number = 5;
  rate: number = 3;
  isReadonly: boolean = true;


}
