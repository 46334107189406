import { Component, OnInit,Input } from '@angular/core';
// class AlertMsg {
//   t:string;
//   constructor(type:string) {
//     this.t=type;
//   }
// }
@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {
  @Input() con:string;
  constructor() {}

  ngOnInit(): void {
    alert(this.con);
  }
  alerts: any[] = [{
    type: 'success',
    msg: `Well done! You successfully read this important alert message. (added: ${new Date().toLocaleTimeString()})`,
    timeout: 5000
  }];
 
  add(): void {
    this.alerts.push({
      type: this.con,
      msg: `This alert will be closed in 5 seconds (added: ${new Date().toLocaleTimeString()})`,
      timeout: 5000
    });
  }
 
  onClosed(dismissedAlert: AlertComponent): void {
    this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }
}
