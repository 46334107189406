import { Component, OnInit } from '@angular/core';
import { SharedService } from "../shared.service";
import { DbService } from '../db.service';
import { Router,ActivatedRoute } from '@angular/router';
import { Airorder } from '../airorder';
import { Address } from '../address';
import { Const } from '../const';
import { UserData } from '../user-data';

@Component({
  selector: 'app-psg-input',
  templateUrl: './psg-input.component.html',
  styleUrls: ['./psg-input.component.css']
})
export class PsgInputComponent implements OnInit {

  constructor(private route:ActivatedRoute, private router: Router,private sharedService: SharedService,private dbService:DbService) { 
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
   }
  }
  ngOnInit(): void {
    
    this.sharedService.updPage(2);
    this.nav=JSON.parse(this.sharedService.getSession('nav'));
    
    if (JSON.parse(this.sharedService.getSession('user'))!= null) {
      this.user = JSON.parse(this.sharedService.getSession('user'));
    }
    this.sharedService.currentAirOrder.subscribe(airOrder =>this.airOrder=airOrder);
    this.updPLeft();

    if ((new Date().getTime()) - (this.user.validStart) > Const.LOGINVALISECOND*1000) this.router.navigate(['/login'],{skipLocationChange:true});
    else{
      this.airOrder.contact_name=this.user.name;
      this.airOrder.Contact_tel=this.user.cc+this.user.tel;
    }
    if (this.airOrder.addList.length==0) {
      this.addForm.name=this.user.name;
      this.addForm.cc=this.user.cc.toString();
      if (this.user.tel!=0) {
        this.addForm.tel=this.user.tel.toString();
      }
      
      this.addForm.address=this.user.add;
      this.addForm.city=this.user.city;
      // this.addForm.province=this.user.province;
    }
    
  }
  title="预定信息";
  loadingHidden: boolean=true;
  addFo:boolean=false;
  modal = { show: false, title: '', body: '' };
  nav;
  user:UserData= new UserData();
  airOrder:Airorder ;
  addForm = {
    "address": "",
    "city": "",
    "province": "ON",
    "name": "",
    "tel": "",
    "cc": "0",
    "person": 0,
    "big": -1,
    "small": -1,
    "p_left": []
  };
  

  // upd_index:number;
  newAdd(form){
    this.addAdd2AirOrder(form);
    this.resetAddForm();
    window.scroll(0,0);
  }
  addFoo(boo){
    this.addFo=boo;
  }
  submitOrder(arr){
    this.airOrder.user_comm=arr[0];
    this.loadingHidden=false;
    this.dbService.callPhp(this.airOrder,'new_air.php').subscribe(
      data=>{
        this.loadingHidden = true;
        // alert(JSON.stringify(data));
        this.modal.body = JSON.stringify(data);
        this.modal.show =true;
      }
    );
  }
  resetAddForm(){
    this.addForm = {
      "address": "",
      "city": "",
      "province": "ON",
      "name": "",
      "tel": "",
      "cc": "0",
      "person": 0,
      "big": -1,
      "small": -1,
      "p_left": []
    };
    this.updPLeft();
  }
  addAdd2AirOrder(form){
    let add = new Address();
    add.add=this.sharedService.cap(form.address.trim());
    add.city=this.sharedService.cap(form.city.trim());
    add.province=form.province.toUpperCase();
    add.name = this.sharedService.cap(form.name);
    add.tel = (form.cc+form.tel);
    add.person = (form.person);
    add.big = (form.big);
    add.small = (form.small);
    this.airOrder.addList.push(add);
    this.airOrder.luggage_large=0;
    this.airOrder.luggage_small=0;
    this.updAirOrderBrief();
    this.checkPrice();
    // this.airOrder.addList[0].add=form.address;
  }
  changeIndex(index){
    this.setAddForm(index);
    this.delAdd(index);
    this.updPLeft();
  }
  setAddForm(index){
    this.addForm = {
      "address": this.airOrder.addList[index].add,
      "city": this.airOrder.addList[index].city,
      "province": this.airOrder.addList[index].province,
      "name": this.airOrder.addList[index].name,
      "tel": this.sharedService.tel2cc(this.airOrder.addList[index].tel.toString())[1],
      "cc": this.sharedService.tel2cc(this.airOrder.addList[index].tel.toString())[0],
      "person": this.airOrder.addList[index].person,
      "big": this.airOrder.addList[index].big,
      "small": this.airOrder.addList[index].small,
      "p_left": []
    };
    
  }
  delAdd(index){
    this.airOrder.addList.splice(index,1);
  }
  updAirOrderBrief(){
    let kwRegion = true;
    this.airOrder.addList.forEach(element => {
      this.airOrder.luggage_large += parseInt(element.big.toString());
      this.airOrder.luggage_small += parseInt(element.small.toString());
      kwRegion = kwRegion&&(Const.KWREGION.includes(element.city));
    });
    if (this.airOrder.flight.outbound) {
      this.airOrder.orig=kwRegion? 'KW':"Other";
    }else{
      this.airOrder.dest = kwRegion ? 'KW' : "Other";
    }
  }
  checkPrice(){
    let price=0;
    this.dbService.callPhp(this.airOrder,'checkprice.php')
    .subscribe(data=> {
      // alert(JSON.stringify(data));
      if(JSON.stringify(data)) price= parseInt(JSON.stringify(data));
      this.airOrder.price=price;
      // this.sharedService.setSession('airorder',JSON.stringify(this.airOrder));
      this.sharedService.updAir(this.airOrder);
      // alert(JSON.stringify(this.airOrder));
    });
    // alert(price);
    // return price;
  }
  updPLeft(){
    this.addForm.p_left=[];
    let p_add=0;
    if (this.airOrder.addList.length>0) {
      this.airOrder.addList.forEach(element => {
        p_add += parseInt(element.person.toString());
      });
    }
    let p=this.airOrder.person - p_add;
    for (let index = 0; index < p; index++) {
      this.addForm.p_left.push(index+1);
      
    }
  }

}
