<div [hidden]="form.p_left.length == 0" class="container mb-5" *ngIf="!dest" >
    <form #addressForm="ngForm" (ngSubmit)="submit(addressForm.value)">
        <div name="name_tel" [hidden]="hide">
           <div class="row caption pl-3 mt-2">请填写实际乘车人姓名</div>
            <div class="row field form-group"><input [(ngModel)]="form.name"  type="text" name="name" placeholder="姓名"></div>
            <div class="row caption pl-3 mt-2">请填乘客乘车时的联系电话</div>
            <div class="row justify-content-around">
                <div class="col-4 pl-0 pr-1"><div class="subfield"><select [(ngModel)]="form.cc" class=" custom-select bg-white" name="cc">
                    <option value=0 disabled>国家区号</option>
                    <option value=1>+1 加/美</option>
                    <option value=86>+86 中国</option>
                </select></div></div>
                <div class="col-8 pl-1 pr-0"><div class="subfield"><input [(ngModel)]="form.tel"  name="tel" type="tel" placeholder="手机号码"></div></div>
            </div> 
        </div>
        <div name="add">
            <div class="row caption pl-3 mt-2 " >请填写需要到达的地址</div>
            <div class="row field"><input [(ngModel)]="form.address" ngxAutocomPlace name="address" placeholder="315 King St North" [options]="options" (selectedPlace)="placeChanged($event)" (focusin)="addFocus()" (focusout)="addFocusout()"></div>
            <div class="row caption mt-2">
                <div class="col-6 caption p-0 pl-3 ">城市</div>
                <div class="col-6 caption p-0 pl-3 ">省份</div>
            </div>
            <div class="row">
                <div class="col-6 pl-0 pr-1"><div class="subfield"><input [(ngModel)]="form.city" name="city" placeholder="城市" ></div></div>
                <div class="col-6 pl-1 pr-0"><div class="subfield"><input [(ngModel)]="form.province" name="province" [disabled]="true" style="text-transform:uppercase" placeholder="省份"></div></div>
            </div>
        </div>
        <div name="per" [hidden]="hide">
            <div class="row caption mt-2 pl-3">该地址的乘客及行李</div>
            <div class="row justify-content-around ">
            <div class="col-4 pl-0 pr-1"><div class="subfield">
                <select [(ngModel)]="form.person" class="custom-select bg-white" name="person">
                    <option class="default" value=0 disabled>人数</option>
                    <option *ngFor="let p of form.p_left" value={{p}}>{{p}}人</option>
         
                </select>
            </div></div>
            <div class="col-4 pl-1 pr-1"><div class="subfield">
                <select [(ngModel)]="form.big" class="custom-select bg-white" name="big">
                    <option value=-1 disabled>托运行李</option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                </select>
            </div></div>
            <div class="col-4 pl-1 pr-0"><div class="subfield">
                <select [(ngModel)]="form.small" class="custom-select bg-white" name="small">
                    <option value=-1 disabled>登机行李</option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                </select>
            </div></div>
        </div>
        </div>
        
        <button [disabled]="form.person==0 || form.address=='' || form.city=='' || form.province=='' || form.name=='' || form.big<0 || form.small<0 " class="btn mt-3" type submit>增加此地址乘车人信息</button>
    </form>
    <div class="mt-5 mb-5" style="height: 30px;"> </div>
</div>




<div class="container mb-5" *ngIf="dest">
    <form #addressForm="ngForm" (ngSubmit)="submit(addressForm.value)">
        <div name="add">
            <div class="row caption pl-3 mt-2 ">请填写需要送达的地址</div>
            <div class="row field"><input [(ngModel)]="form.address" ngxAutocomPlace name="address"
                    placeholder="315 King St North" [options]="options" (selectedPlace)="placeChanged($event)"
                    (focusin)="addFocus()" (focusout)="addFocusout()"></div>
            <div class="row caption mt-2">
                <div class="col-6 caption p-0 pl-3 ">城市</div>
                <div class="col-6 caption p-0 pl-3 ">省份</div>
            </div>
            <div class="row">
                <div class="col-6 pl-0 pr-1">
                    <div class="subfield"><input [(ngModel)]="form.city" name="city" placeholder="城市"></div>
                </div>
                <div class="col-6 pl-1 pr-0">
                    <div class="subfield"><input [(ngModel)]="form.province" name="province" [disabled]="true"
                            style="text-transform:uppercase" placeholder="省份"></div>
                </div>
            </div>
        </div>
        

        <button
            [disabled]=" form.address=='' || form.city=='' || form.province=='' "
            class="btn mt-3" type submit>增加此下车地址</button>
    </form>
    <div class="mt-5 mb-5" style="height: 30px;"> </div>
</div>

<!-- {{selectedPlace.url}}<br><br>
{{selectedPlace|json}} -->
