<!-- <div class="container " *ngFor= "let address of airOrder.addList;let i =index" >
    <div class="row">
        <div class="col-10 info">
            <div class="row info">
                <div class="col-3 info">{{address.name}}</div>
                <div class="col-4 info">+{{address.tel}}</div>
                <div class="col-2 info">{{address.person}}人</div>
                <div class="col-3 info">{{address.big}}大{{address.small}}小</div>
            </div>
            <div class="row info">
                <div class="col-3 info"></div>
                <div class="col-9 info">{{address.add}}, {{address.city}} {{address.province}}</div>
            </div>
            
        </div>
        <div class="col-2 changebox ">
            <div class="change text-center " (click)="change(i)">修改</div>
        </div>
    </div>
</div> -->

<div class="container " *ngFor="let address of addList;let i =index;let last = last">
    <div class="row">
        <div class="col-10 info">
            <div class="row info" >
                    <div class="col-3 info" >{{address.name}} </div>
                    <div class="col-4 info" *ngIf="!dest">+{{address.tel}}</div>
                    <div class="col-2 info" *ngIf="!dest">{{address.person}}人</div>
                    <div class="col-3 info" *ngIf="!dest">{{address.big}}大{{address.small}}小</div>               
            </div>
            <div class="row info">
                <div class="col-3 info"></div>
                <div class="col-9 info">{{address.add}}, {{address.city}} {{address.province}}</div>
            </div>
        </div>
        <div class="col-2 changebox ">
            <div class="change text-center " (click)="change(i)">修改</div>
        </div>
    </div>
    <div *ngIf="!last">
        <div class="btm_line mt-1"> </div>
    </div>
    
</div>
