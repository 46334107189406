import { Component, OnInit,Input } from '@angular/core';
import { trigger, transition, style, animate } from "@angular/animations";
import {interval,Subscription } from 'rxjs';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate('300ms', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class CarouselComponent implements OnInit {
  @Input() images: any;
  mySubscription: Subscription;
  constructor() {
    this.mySubscription= interval(5000).subscribe((x =>{
      this.onNextClick();
    }));
   }

  ngOnInit(): void {
    
  }
  
  currentSlide = 0;
  onPreviousClick() {
    const previous = this.currentSlide - 1;
    this.currentSlide = previous < 0 ? this.images.length - 1 : previous;
    console.log("previous clicked, new current slide is: ", this.currentSlide);
  }

  onNextClick() {
    const next = this.currentSlide + 1;
    this.currentSlide = next === this.images.length ? 0 : next;
    console.log("next clicked, new current slide is: ", this.currentSlide);
  }

}
