import { Component, OnInit,Input,Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-head',
  templateUrl: './head.component.html',
  styleUrls: ['./head.component.css']
})
export class HeadComponent implements OnInit {

  constructor(private router: Router) { }
  @Input() title:string;
  @Input() bk:string;
  @Input() keepUrl:boolean;
  @Input() step: number;
  @Output() backstep = new EventEmitter();
  ngOnInit(): void {
  }
  backto(){
    if (this.step ==0)    this.router.navigate(['/' + this.bk], { skipLocationChange: this.keepUrl });
    else this.backstep.emit(1);
  }
  

}
