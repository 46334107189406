import { Component, Input, OnInit } from '@angular/core';
// import { Rideorder } from '../rideorder';
// import { UserData } from '../user-data';
// import { SharedService } from "../shared.service";
// import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ride-brief',
  templateUrl: './ride-brief.component.html',
  styleUrls: ['./ride-brief.component.css']
})
export class RideBriefComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  @Input() rideOrder;

}
