export class Flight {
    depart_date: string='';
    ident_iata: string='';
    ident_icao: string='';
    depart_time:string='';
    land_date:string='';
    land_time:string='';
    orig_city: string='';
    dest_city:string='';
    orig_port: string='';
    dest_port:string='';
    outbound:boolean;
}
