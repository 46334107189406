import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ElementRef} from '@angular/core';
import {Address} from '../address';
import { SharedService } from "../shared.service";
import { Airorder } from '../airorder';
import {ActivatedRoute} from '@angular/router';

// import {MapsAPILoader} from '@agm/core';

// import {Const} from '../const';


@Component({
  selector: 'add-input',
  templateUrl: './add-input.component.html',
  styleUrls: ['./add-input.component.css']
})
export class AddInputComponent implements OnInit {
  @Input() form;
  @Input() dest:boolean;
  @Output() newAdd= new EventEmitter();
  // @Output() addFoo=new EventEmitter();
  constructor(private route: ActivatedRoute, private sharedService: SharedService, private ref: ChangeDetectorRef, private Elm: ElementRef) {   }

  ngOnInit(): void {
    // alert(JSON.stringify(this.form));
  }
  airOrder:Airorder;
  // default:number=0;
  defaultForm;
  hide=false;
 
  submit(form){
    this.newAdd.emit(this.form);
  }
  addFocus(){
    // this.addFoo.emit(true);
    // this.hide=true;
    // window.scroll(0,0);
  }
  addFocusout(){
    // this.hide=false;
    // this.addFoo.emit(false);
  }

  title = 'ngx Autocomplete Google Places';
  selectedPlace: any;
  json = JSON;
  options = {
    componentRestrictions: {
      country: 'ca'
    }
  }

  placeChanged(place) {
    this.selectedPlace = place;
    this.ref.detectChanges();
    let num='';
    let add='';
    this.selectedPlace.address_components.forEach(element => {
      if (element.types.includes("street_number")) {
        num = element.short_name;
      } else if (element.types.includes("route")) {
        add = element.short_name;
      } else if (element.types.includes("administrative_area_level_1")) {
        this.form.province = element.short_name;
      } else if (element.types.includes("locality")) {
        this.form.city = element.short_name;
      } 
      
    });
    if (num == '' || add == '') this.form.address=this.selectedPlace.name;
    else this.form.address = num+" "+add;

    this.selectedPlace.address_components.forEach(element => {
      if (element.types.includes("sublocality") && element.short_name=="North York") {
        this.form.city = element.short_name;
      }
    });
    // test address:
    // 222 spadina ave toronto
    // 58 cardill
    // 5535 yonge st North York
    //3401 dufferin st North York
  }
}
